import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pin-call-tooltip',
  templateUrl: './call-tooltip.component.html',
  styleUrls: ['./call-tooltip.component.less']
})
export class CallTooltipComponent implements OnInit {
  ngOnInit() {}
}
