import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirstStepComponent } from './components/first-step/first-step.component';
import { FourthStepComponent } from './components/fourth-step/fourth-step.component';
import { SecondStepComponent } from './components/second-step/second-step.component';
import { ThirdStepComponent } from './components/third-step/third-step.component';
import { IsTokenService } from './services/is-token.service';
import { ValidHrefService } from './services/valid-href.service';

const routes: Routes = [
  {
    path: 'firstStep',
    pathMatch: 'full',
    component: FirstStepComponent,
    canActivate: [IsTokenService],
    data: { animation: '1' }
  },
  {
    path: 'secondStep',
    pathMatch: 'full',
    component: SecondStepComponent,
    canActivate: [ValidHrefService],
    data: { animation: '2' }
  },
  {
    path: 'thirdStep',
    pathMatch: 'full',
    component: ThirdStepComponent,
    canActivate: [ValidHrefService],
    data: { animation: '3' }
  },
  {
    path: 'fourthStep',
    pathMatch: 'full',
    component: FourthStepComponent,
    canActivate: [ValidHrefService],
    data: { animation: '4' }
  },
  { path: '', redirectTo: 'firstStep', pathMatch: 'full' },
  { path: '**', redirectTo: 'firstStep' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
