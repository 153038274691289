import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NextStepService {
  isFrame: boolean;

  constructor(private http: HttpClient) {}

  public nextSecondStep(form: FormGroup): Observable<any> {
    const cardNumberValue = form.value.cardNumber.trim().replace(/\s/g, '');

    return this.http.post('/api/initSession/', {
      cardNumber: cardNumberValue,
      expirationYear: form.value.cardYear,
      expirationMonth: form.value.cardMonth,
      captcha: form.value.captcha
    });
  }

  public nextThirdStep(form: FormGroup, isActive): Observable<any> {
    const data = isActive
      ? { pin: form.value.cardPin }
      : { pin: form.value.cardPin, activate: form.value.active, accepted: form.value.agree };

    return this.http.post('/api/changePin/', data);
  }

  public nextFourthStep(code: string): Observable<any> {
    return this.http.post('/api/checkCode/', {
      code: +code
    });
  }
}
