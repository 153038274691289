import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
const animateProp = '300ms cubic-bezier(.25,.8,.25,1)';
export const routeChangeAnimation = trigger('routeChangeAnimation', [
  transition('* => *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ right: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate(animateProp, style({ right: '100%' }))], { optional: true }),
      query(':enter', [animate(animateProp, style({ right: '0%' }))], { optional: true })
    ]),
    query(':enter', animateChild(), { optional: true })
  ])
]);
export const showAgreement = trigger('enterAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate(animateProp, style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate(animateProp, style({ opacity: 0 }))])
]);
