import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NextStepService } from '../../services/next-step.service';

@Component({
  selector: 'pin-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.less']
})
export class ThirdStepComponent implements OnInit {
  activeLoader = false;

  @ViewChild('pin', { static: false }) pin;

  formActivePin: FormGroup = new FormGroup({
    cardActivePin: new FormControl('', [Validators.required, Validators.minLength(4)])
  });

  constructor(private router: Router, private nextStepService: NextStepService) {}

  ngOnInit() {}

  changeInput(e) {
    const result = e.target.value.replace(/[^0-9+]/g, '');
    this.formActivePin.controls['cardActivePin'].setValue(result);
  }

  onClickNext(e: MouseEvent) {
    e.preventDefault();
    if (this.formActivePin.invalid) {
      sessionStorage.removeItem('prevStep');

      return false;
    }
    this.activeLoader = true;
    this.nextStepService.nextFourthStep(this.formActivePin.controls['cardActivePin'].value).subscribe(
      result => {
        const res: any = result;
        if ((res.activation === 'notNeeded' || res.activation === 'activated') && res.pinChange === 'pinChanged') {
          const validTrue = JSON.stringify(this.formActivePin.valid);
          sessionStorage.setItem('prevStep', validTrue);
          this.router.navigate(['/fourthStep']);
        }
      },

      error => {
        this.activeLoader = false;
        if (error.error && error.error.code) {
          const parent = this.pin.nativeElement.closest('.entry-input_wrap');
          const elem = document.createElement('div');
          elem.classList.add('error-wrapper', 'error-back');
          parent.appendChild(elem);
          elem.style.cssText = 'display: block; text-align: left;';
          switch (error.error.code) {
            case 500: {
              elem.innerHTML =
                'Код введен неверно. Пожалуйста, <a class="a-white" href="/firstStep">начните сначала</a>';
              break;
            }
            case 99: {
              elem.textContent = 'Сервис временно недоступен, просьба обратиться позднее';
              break;
            }
            case -30: {
              elem.textContent = 'Введен неправильный код.';
              break;
            }
            case -31: {
              elem.innerHTML =
                'Формирование ПИН-кода не завершено. ' +
                '<a class="a-white" target="_blank" href="https://www.rsb.ru/">Напишите нам в Чат</a>';
              break;
            }
            case -32: {
              elem.innerHTML =
                'Активация карты не завершена. ' +
                '<a class="a-white" target="_blank" href="https://www.rsb.ru/">Напишите нам в Чат</a>';
              break;
            }
            case -10: {
              elem.innerHTML = 'Сессия истекла. Пожалуйста, <a class="a-white" href="/firstStep">начните сначала</a>';
              break;
            }
          }
        }
      }
    );
  }

  onFocus() {}
}
