import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorsService } from '../../services/errors.service';
import { NextStepService } from '../../services/next-step.service';
import { ValidationService } from '../../../../../../../libs/validation/src';

@Component({
  selector: 'pin-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.less']
})
export class FirstStepComponent implements OnInit {
  entryForm: FormGroup;
  readonly CLOSE_EYE: string = 'close-eyes';
  showKbd = false;

  nextEl: Element;
  test: any;
  inputName = 'cardNumber';

  @ViewChild('card_number', { static: false }) elementCardNumber: ElementRef;

  @ViewChild('cardNumberLook', { static: false }) cardNumberLook: ElementRef;

  @ViewChild('card_month', { static: false }) elementCardMonth: ElementRef;

  @ViewChild('card_year', { static: false }) elementCardYear: ElementRef;

  @ViewChild('elementCaptcha', { static: false }) elementCaptcha: ElementRef;

  @ViewChild('captchaImg', { static: false }) captchaImg: ElementRef;

  constructor(
    private router: Router,
    private r2: Renderer2,
    private nextStepService: NextStepService,
    public errorsMessage: ErrorsService,
    private validators: ValidationService
  ) {}

  ngOnInit() {
    sessionStorage.clear();
    this.entryForm = new FormGroup(
      {
        cardNumber: new FormControl('', [Validators.required, Validators.maxLength(19), Validators.minLength(17)]),
        cardMonth: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2),
          this.validators.validatePattern
        ]),
        cardYear: new FormControl('', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
        ]),
        captcha: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
      }
    );
  }

  onClickNext(e) {
    e.preventDefault();
    if (this.entryForm.invalid) {
      this.entryForm.get('captcha').setValue('');
      this.refresh();

      return false;
    }
    this.nextStepService.nextSecondStep(this.entryForm).subscribe(
      value => {
        const serialModel = JSON.stringify(this.entryForm.value);
        const validTrue = JSON.stringify(this.entryForm.valid);
        const active = JSON.stringify(value);
        // sessionStorage.setItem('cardInfo', serialModel);
        sessionStorage.setItem('prevStep', validTrue);
        sessionStorage.setItem('activate', active);
        this.router.navigate(['/secondStep']);
      },
      error => {
        this.entryForm.value.captcha = '';
        this.refresh();
        if (error.error && error.error.code) {
          const parent = this.elementCardNumber.nativeElement.closest('.entry-input_wrap');
          const elem = document.createElement('div');
          elem.classList.add('error-wrapper', 'error-back');
          parent.appendChild(elem);
          elem.style.cssText = 'display: block; text-align: left;';
          switch (error.error.code) {
            case 400: {
              elem.textContent = 'Введенный код не совпадает, с кодом на картинке';
              break;
            }
            case 500: {
              elem.textContent = 'Ошибка ввода данных';
              break;
            }
            case -1: {
              elem.innerHTML =
                'Изменение на сайте доступно 1&nbsp;раз в 30 дней. Попробуйте позже или воспользуйтесь ' +
                '<a class="a-white" href="https://www.rsb.ru/faq/#faq-group43">другим способом</a>.';
              break;
            }
            case 99: {
              elem.textContent = 'Сервис временно недоступен, просьба обратиться позднее';
              break;
            }
          }
        }
      }
    );
  }

  inputValueChange(currName, valueKbd, nextEl = null) {
    const Amount = document.querySelector(`[formControlName="${currName}"]`).getAttribute('maxLength');
    const Element = this.entryForm.controls[currName];
    let maskNumber = Element.value + valueKbd;
    maskNumber = maskNumber
      .replace(/[^0-9]+/g, '')
      .replace(/./g, (s, i, str) => ((i + 1) % 4 || i === str.length - 1 ? s : `${s} `));
    if (maskNumber.length <= Amount) {
      Element.setValue(maskNumber);
      if (Element.value.length === +Amount) {
        nextEl.focus();
      }
    }
  }

  onClickKey(value) {
    this.inputValueChange(this.inputName, value, this.nextEl);
  }

  onDelete() {
    const Element = this.entryForm.controls[this.inputName];
    Element.setValue(Element.value.substring(0, Element.value.length - 1));
  }

  onClickChangeLook(e) {
    const inputs = e.target.closest('.entry-input_wrap').querySelectorAll('input');
    if (e.target.classList.contains(this.CLOSE_EYE)) {
      this.r2.removeClass(e.target, this.CLOSE_EYE);
      inputs.forEach(elem => elem.setAttribute('type', 'text'));
    } else {
      this.r2.addClass(e.target, this.CLOSE_EYE);
      inputs.forEach(elem => elem.setAttribute('type', 'password'));
    }
  }

  showKeyboard() {
    this.showKbd = !this.showKbd;
  }

  onFocus(e) {
    this.inputName = e.target.getAttribute('formControlName');
    e.target.setAttribute('type', 'text');
    if (this.inputName !== 'captcha') {
      const eyes = e.target.closest('.entry-card').querySelectorAll('.img-look');
      eyes.forEach(elem => {
        if (elem.previousElementSibling.getAttribute('formControlName') === this.inputName) {
          elem.classList.remove(this.CLOSE_EYE);
        } else {
          elem.classList.add(this.CLOSE_EYE);
        }
      });
    } else {
      Array.from(document.querySelectorAll('.img-look')).forEach(item => item.classList.add(this.CLOSE_EYE));
      this.showKbd = false;
    }
    Array.from(Object.keys(this.entryForm.controls)).forEach(el => {
      if (el !== this.inputName && this.entryForm.value[el] && el !== 'captcha') {
        document.querySelector(`[formControlName="${el}"]`).setAttribute('type', 'password');
      }
    });
  }

  refresh() {
    this.elementCaptcha.nativeElement.value = '';
    this.captchaImg.nativeElement.setAttribute('src', `/api/captcha?${Math.random() * 1e7}`);
  }

  onChange(nextEl, currentEl) {
    this.nextEl = nextEl;
    currentEl.type = 'text';
  }

  translateToEn() {
    const keys = {
      й: 'q',
      ц: 'w',
      у: 'e',
      к: 'r',
      е: 't',
      н: 'y',
      г: 'u',
      ш: 'i',
      щ: 'o',
      з: 'p',
      ф: 'a',
      ы: 's',
      в: 'd',
      а: 'f',
      п: 'g',
      р: 'h',
      о: 'j',
      л: 'k',
      д: 'l',
      я: 'z',
      ч: 'x',
      с: 'c',
      м: 'v',
      и: 'b',
      т: 'n',
      ь: 'm',
      б: '',
      ю: '',
      х: '',
      ж: '',
      э: '',
      ъ: ''
    };
    const captcha = this.entryForm.controls['captcha'];
    const res = captcha.value.replace(/[^а-яa-z\d]/gi, '').replace(/./g, match => {
      if (match === match.toLowerCase()) {
        return keys[match] !== undefined ? keys[match] : match;
      }

      return keys[match.toLowerCase()] !== undefined ? keys[match.toLowerCase()].toUpperCase() : match;
    });
    captcha.setValue(res);
  }

  onClickedOutside(e) {
    if (!e.target.classList.contains('virtual-kbd_img')) {
      this.showKbd = !this.showKbd;
    }
  }
}
