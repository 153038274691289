import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

interface Errors {
  required: string;
  minlength: string;
  maxlength: string;
  validateYear: string;
  validatePinValueConsist: string;
  validatePinValueEqual: string;
  validatePinValuePalindrom: string;
  validateIsEqualPin: string;
  validatePattern: string;
}
@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  prop: string;

  error: string;

  errorsList: Errors = {
    required: 'Поле не должно быть пустым!',
    minlength: '',
    maxlength: '',
    validateYear: 'Введите корректный год.',
    validatePinValueConsist: 'ПИН код не может состоять из последовательных цифр.',
    validatePinValueEqual: 'ПИН код не может состоять из одинаковых цифр.',
    validatePinValuePalindrom: 'ПИН код не может состоять из зеркальных цифр.',
    validateIsEqualPin: 'Значения должны совпадать!',
    validatePattern: 'Введите корректный месяц.'
  };

  getErrorMessage(control: AbstractControl, max = null, min = null) {
    this.errorsList.minlength = `Введите не менее ${min} символов.`;
    this.errorsList.maxlength = `Введите не более ${max} символов.`;
    this.prop = Object.keys(control.errors).find(el => control.errors[el]);
    this.error = this.errorsList[this.prop];

    return !!this.prop;
  }
}
