import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable } from '@angular/core';
@Injectable()
export class ValidationService {
  validateSpecSymbol(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^а-яёa-z\d\s()-]/gi.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateSpecSymbol: {
          ru: 'Разрешены только буквы и цифры',
          en: 'en',
        },
      };
  }

  validateAmount(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = /^\d+(\.\d+)?$/.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateAmount: {
          ru: 'Разрешены только цифры. Например:12.25',
          en: 'en',
        },
      };
  }

  validateReviewUpload(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    return value && value.length
      ? null
      : {
        validateReviewUpload: {
          ru: 'Загрузите файл или измените свой ответ на 4 шаге о наличии отзывов.',
          en: 'en',
        },
      };
  }

  validateDigit(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = /^\d+$/.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateDigit: {
          ru: 'Разрешены только цифры',
          en: 'en',
        },
      };
  }

  validateIP(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = /^\d{2}\.\d{2}\.\d{2}\.\d{3}$/.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateIP: {
          ru: 'Введите в формате 11.11.11.111',
          en: 'en',
        },
      };
  }

  validateLangAll(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^a-zа-яё]/i.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateLangAll: {
          ru: 'Разрешена латиница и кирилица',
          en: 'en',
        },
      };
  }

  validateLangRu(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^а-яё]/gi.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateLangRu: {
          ru: 'Разрешена  кирилица',
          en: 'en',
        },
      };
  }

  validateLangEn(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^a-z]/gi.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateLangEn: {
          ru: 'Разрешена латиница',
          en: 'en',
        },
      };
  }

  validateWordOrDigit(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^a-z\d]/gi.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateWordOrDigit: {
          ru: 'Разрешена латиница и цифра',
          en: 'en',
        },
      };
  }
  // /^(http\:\/\/|https\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*$/i
  // /[-a-zA-Z0-9@:%_\+.~#?&\/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/=]*)?/gi
  validateURL(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp =
      /^(http\:\/\/|https\:\/\/)?([a-zа-я0-9][a-zа-я0-9\-]*\.)+[a-zа-я0-9][a-zа-я0-9\-]*$/i.test(
        value
      );

    return resultRegExp || !value
      ? null
      : {
        validateURL: {
          ru: 'Введите корректный url.',
          en: 'Url is not a valid url address.',
        },
      };
  }

  validateEmail(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp =
      /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(
        value
      );

    return resultRegExp || !value
      ? null
      : {
        validateEmail: {
          ru: 'Введите корректный e-mail.',
          en: 'Email is not a valid email address.',
        },
      };
  }

  validateEmailWithCyrillic(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp =
      /^([a-zа-я0-9_-]+\.)*[a-zа-я0-9_-]+@[a-zа-я0-9_-]+(\.[a-zа-я0-9_-]+)*\.[a-zа-я]{2,6}$/i.test(
        value
      );

    return resultRegExp || !value
      ? null
      : {
        validateEmailWithCyrillic: {
          ru: 'Введите корректный e-mail.',
          en: 'Email is not a valid email address.',
        },
      };
  }

  validateName(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^a-z\s]/gi.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateName: {
          ru: 'Имя держателя карты не верен',
          en: 'Card Holder Name is invalid.',
        },
      };
  }

  validateBirthday(control: AbstractControl) {
    const { value } = control;

    const resultRegExp = /^[0123]\d\/[01]\d\/((20)|(19))\d{2}$/.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateBirthday: {
          ru: 'Неправильный формат поля Дата рождения заявителя.',
          en: 'Date of birth cannot be blank.',
        },
      };
  }

  validateIssueDate(controlBirthDate: AbstractControl) {
    return (control: FormControl) => {
      const { value } = control;
      const dateOfBirth = controlBirthDate.value;
      const getAge = (date: Date): number => {
        const today = new Date();

        let age = today.getFullYear() - date.getFullYear();
        const m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
          age--;
        }

        return age;
      };

      const isPassportValid = (
        birthDate: Date,
        passportDate: Date,
      ): boolean => {
        const age = getAge(birthDate);
        const datePassportChange = new Date(birthDate);
        const passportDateMin = new Date(birthDate);

        passportDateMin.setFullYear(passportDateMin.getFullYear() + 14);
        if (passportDate.getTime() < passportDateMin.getTime()) {
          return false;
        }
        if (age >= 45) {
          datePassportChange.setFullYear(datePassportChange.getFullYear() + 45);
        } else if (age >= 20) {
          datePassportChange.setFullYear(datePassportChange.getFullYear() + 20);
        } else return age >= 14 && age < 20;

        return passportDate >= datePassportChange;
      };

      if (value && !isPassportValid(new Date(dateOfBirth), new Date(value))) {
        return {
          validateIssueDate: 'Срок действия паспорта истек'
        };
      }

      return null;
    };
  }


  validatePhone(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = /^\d{11,12}$/.test(value);

    return resultRegExp || !value
      ? null
      : {
        validatePhone: {
          ru: 'Номер телефона должен начинаться с кода страны и содержать 11 цифр',
          en: 'Phone number should start with country code and contain 11 digits',
        },
      };
  }

  validatePhoneCode(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = /(\(\d{2,5}\))(\d{7})/gm.test(value);

    return resultRegExp || !value
      ? null
      : {
        validatePhoneCode: {
          ru: 'Номер телефона некорректен',
          en: 'Phone number invalid',
        },
      };
  }

  validatePhoneWithoutCode(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = /^\d{10}$/.test(value);

    return resultRegExp || !value
      ? null
      : {
        validatePhone: {
          ru: 'Номер телефона должен содержать 10 цифр',
          en: 'Phone number should contain 10 digits',
        },
      };
  }

  validateINN(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    return !value || value.length === 10 || value.length === 12
      ? null
      : {
        validateINN: {
          ru: `Введите корректный ИНН.`,
          en: 'en',
        },
      };
  }

  validateOKPO(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    return !value || value.length === 8 || value.length === 10
      ? null
      : {
        validateOKPO: {
          ru: `Введите корректный код`,
          en: 'en',
        },
      };
  }

  validateMinLength(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      return !value || value.length >= number
        ? null
        : {
          validateMinLength: {
            ru: `Введите не менее ${number} символов`,
            en: 'en',
          },
        };
    };
  }

  validateMaxLength(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      return !value || value.length <= number
        ? null
        : {
          validateMaxLength: {
            ru: `Введите не более ${number} символов`,
            en: 'en',
          },
        };
    };
  }

  validateMaxExpireTime(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      return !value || value <= number
        ? null
        : {
          validateMaxExpireTime: {
            ru: `Максимальное время ${number} минут`,
            en: 'en',
          },
        };
    };
  }

  validateMinExpireTime(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      return !value || value >= number
        ? null
        : {
          validateMinExpireTime: {
            ru: `Минимальное время ${number} минута`,
            en: 'en',
          },
        };
    };
  }

  validateRangeDate(start: number, end: number) {
    return (control: FormControl) => {
      const { value } = control;
      if (value) {
        const controlValue = new Date(value);
        const min = new Date();
        const max = new Date();

        min.setFullYear(new Date().getFullYear() - end);
        max.setFullYear(new Date().getFullYear() - start);
        min.setHours(0, 0, 0, 0);
        max.setHours(0, 0, 0, 0);
        controlValue.setHours(0, 0, 0, 0);
        if (
          controlValue.getTime() >= max.getTime() &&
          controlValue.getTime() <= min.getTime()
        ) {
          return null;
        } else {
          return {
            validateRangeDate: `Ваш возраст должен быть в диапазоне от ${end} до ${start} лет`,
          };
        }
      }

      return null;
    };
  }

  validateRequired(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    return value
      ? null
      : {
        validateRequired: {
          ru: 'Заполните поле.',
          en: 'Field cannot be blank.',
        },
      };
  }

  validateCorrectPassword(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    // eslint-disable-next-line max-len
    const resultRegExp =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{7,}/.test(value);

    return resultRegExp || !value
      ? null
      : { validateCorrectPassword: 'Пароль недостаточно безопасный' };
  }

  validateSymbolOrDigit(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^0-9,.]/gi.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateSymbolOrDigit: 'Разрешены цифры и символы «,» «.»',
      };
  }

  validateLatinDigitSym(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^a-z0-9-!"#$%^&'()*+,./:;<=>?@[\\\]_`{|}~]/gi.test(
      value
    );

    return resultRegExp || !value
      ? null
      : {
        validateLatinDigitSym: 'Разрешена латиница, цифры, символы',
      };
  }

  validateFIO(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const resultRegExp = !/[^a-zа-яё \\-]/i.test(value);

    return resultRegExp || !value
      ? null
      : {
        validateFIO: 'Разрешена латиница, кириллица и символ «-»',
      };
  }

  validateRadioBtns(form: FormGroup) {
    const { controls } = form;
    for (const key in controls) {
      if (Object.values(controls[key].value).some((value) => value)) {
        return null;
      }
    }

    return {
      validateRadioBtns: 'Выберите один из пунктов',
    };
  }

  validateFormValue(form: FormGroup) {
    const { controls } = form;

    for (const key in controls) {
      if (controls[key].value) {
        return null;
      }
    }

    return {
      validateFormValue: 'Поля формы пустые',
    };
  }

  validPass(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ): (ValidatorFn) {
    // @ts-ignore
    return (form: FormGroup): { [key: string]: any } | null => {
      let oldPass;
      if (form.controls[oldPassword]) {
        oldPass = form.controls[oldPassword];
      }
      const newPass = form.controls[newPassword];
      const confirmPass = form.controls[confirmPassword];

      if (oldPass && newPass.value && oldPass.value === newPass.value) {
        newPass.setErrors({
          validateNewPass: 'Пароль не должен совпадать с предыдущим',
        });
      }

      if (confirmPass.value && newPass.value !== confirmPass.value) {
        confirmPass.setErrors({
          validateConfirmPass: 'Пароли должны совпадать',
        });
      }

      return null;
    };
  }

  // @ts-ignore
  validateYear(control: FormControl) {
    const { value } = control;
    const year = +new Date()
      .getFullYear()
      .toString()
      .substr(-2);
    if (value.length > 1) {
      return +value >= year ? null : { validateYear: true };
    }
  }

  // @ts-ignore
  validateCheckMonth(form: FormGroup) {
    const year = new Date()
      .getFullYear()
      .toString()
      .substr(-2);
    const month = +new Date().getMonth().toString() + 1;
    // @ts-ignore
    if (form.get('cardMonth').valid && form.get('cardYear').valid && form.get('cardYear').value === year) {
      // @ts-ignore
      return +form.get('cardMonth').value >= month ? null : { validateCheckMonth: true };
    }
  }

  // @ts-ignore
  validatePattern(control: FormControl) {
    if (control.value.length > 1) {
      return control.value.search(/(0?[1-9]|1[012])$/g) ? { validatePattern: true } : null;
    }
  }

  validatePinValueConsist(control: FormControl) {
    let { value } = control;
    if (value && value.length === 4) {
      value = value
        .split('')
        .filter((el: any, i: number, arr: (string | number)[]) => i !== 0 && Math.abs(+arr[i] - +arr[0]) !== i)
        .join('');
    }

    return value ? null : { validatePinValueConsist: true };
  }

  validatePinValueEqual(control: FormControl) {
    const { value } = control;
    let res;
    if (value && value.length === 4) {
      res = value.split('').every((el: any) => el === value[0]);
    }

    return res ? { validatePinValueEqual: true } : null;
  }

  // @ts-ignore
  validatePinValuePalindrom(control: FormControl) {
    const { value } = control;
    let res;
    if (value) {
      res = value.split('').every((el: any) => el === value[0]);
      if (value.length === 4 && !res) {
        const arr = value.split('');
        res =
          arr.slice(0, arr.length / 2).join() ===
          arr
            .slice(arr.length / 2, arr.length)
            .reverse()
            .join();

        return res ? { validatePinValuePalindrom: true } : null;
      }
    }
  }

  // @ts-ignore
  validateCheckbox(isActive: boolean): ValidatorFn {
    if (!isActive) {
      return (control: AbstractControl) => (control.value ? null : { validateCheckbox: true });
    }
  }

  validateIsEqualPin(form: FormGroup) {
    const basicPin = form.controls['cardPin'];
    const comparedPin = form.controls['cardPinRepeat'];
    const equal =
      basicPin.valid && comparedPin.value && comparedPin.value.length === 4 && basicPin.value === comparedPin.value;

    return equal ? null : { validateIsEqualPin: true };
  }
}
