import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidHrefService implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (sessionStorage.getItem('prevStep') === 'false') {
      location.href = '/firstStep';

      return false;
    }
    if (sessionStorage.getItem('prevStep') === null) {
      location.href = '/firstStep';

      return false;
    }
    sessionStorage.setItem('prevStep', 'false');

    return true;
  }
}
