import { Component, OnInit } from '@angular/core';
import { NextStepService } from '../../services/next-step.service';

@Component({
  selector: 'pin-fourth-step',
  templateUrl: './fourth-step.component.html',
  styleUrls: ['./fourth-step.component.less']
})
export class FourthStepComponent implements OnInit {
  isFrame: boolean;

  constructor(private nextStepService: NextStepService) {}

  ngOnInit() {
    this.isFrame = this.nextStepService.isFrame;
  }
}
