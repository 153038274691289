import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() isFrame: boolean;

  constructor(public router: Router) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isFrame = changes.isFrame.currentValue;
  }
}
