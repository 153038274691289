import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pin-tooltips',
  templateUrl: './tooltips.component.html',
  styleUrls: ['./tooltips.component.less']
})
export class TooltipsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
