import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pinOpenEyes]'
})
export class OpenEyesDirective {
  self: Element;

  parent: Element;

  constructor(private elementRef: ElementRef, private r2: Renderer2) {
    this.self = elementRef.nativeElement;
    this.parent = this.self.parentElement;
  }

  @HostListener('focus') onFocus() {
    Array.from(this.parent.children).forEach(el => {
      if (el.tagName === 'INPUT') {
        el.setAttribute('type', 'text');
      }
      if (el.classList.contains('img-look')) {
        el.classList.remove('close-eyes');
      }
    });
  }

  @HostListener('blur') onBlur() {
    Array.from(this.parent.children).forEach(el => {
      if (el.tagName === 'INPUT') {
        el.setAttribute('type', 'password');
      }
      if (el.classList.contains('img-look')) {
        el.classList.add('close-eyes');
      }
    });
  }
}
