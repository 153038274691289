import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { FirstStepComponent } from './components/first-step/first-step.component';
import { FourthStepComponent } from './components/fourth-step/fourth-step.component';
import { HeaderComponent } from './components/header/header.component';
import { SecondStepComponent } from './components/second-step/second-step.component';
import { ThirdStepComponent } from './components/third-step/third-step.component';

import { CallTooltipComponent } from './components/tooltips/call-tooltip/call-tooltip.component';
import { ChatTooltipComponent } from './components/tooltips/chat-tooltip/chat-tooltip.component';
import { TooltipsComponent } from './components/tooltips/tooltips.component';
import { VKeyboardComponent } from './components/v-keyboard/v-keyboard.component';
import { OpenEyesDirective } from './directives/open-eyes.directive';
import { ValidationService } from '../../../../../libs/validation/src';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TooltipsComponent,
    CallTooltipComponent,
    ChatTooltipComponent,
    FirstStepComponent,
    SecondStepComponent,
    ThirdStepComponent,
    FourthStepComponent,
    OpenEyesDirective,
    VKeyboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    BrowserAnimationsModule,
    ClickOutsideModule
  ],
  providers: [
    ValidationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
