import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { showAgreement } from '../../app.animations';
import { ErrorsService } from '../../services/errors.service';
import { NextStepService } from '../../services/next-step.service';
import { ValidationService } from '../../../../../../../libs/validation/src';

@Component({
  selector: 'pin-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.less'],
  animations: [showAgreement]
})
export class SecondStepComponent implements OnInit {
  formPin: FormGroup;

  inputName = 'cardPin';
  readonly CLOSE_EYE: string = 'close-eyes';
  showKbd = false;

  nextEl: Element;

  @ViewChild('card', { static: false }) card: ElementRef;

  @ViewChild('cardPin', { static: false }) cardPin: ElementRef;

  isActiveCard: boolean;

  constructor(
    private router: Router,
    private r2: Renderer2,
    private nextStepService: NextStepService,
    public errorsMessage: ErrorsService,
    private validators: ValidationService
  ) {}

  ngOnInit() {
    const activeCard = JSON.parse(sessionStorage.getItem('activate'));
    this.isActiveCard = activeCard.activationState === 'activated';
    this.formPin = new FormGroup(
      {
        cardPin: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          this.validators.validatePinValueConsist,
          this.validators.validatePinValueEqual,
          this.validators.validatePinValuePalindrom
        ]),
        cardPinRepeat: new FormControl('', [Validators.required]),
        active: new FormControl(false, this.validators.validateCheckbox(this.isActiveCard)),
        agree: new FormControl(false, this.validators.validateCheckbox(this.isActiveCard))
      },
      { validators: this.validators.validateIsEqualPin }
    );
  }

  changeInput(nextInput, value) {
    const input = this.formPin.controls[this.inputName];
    const amount = document.querySelector(`[formControlName="${this.inputName}"]`).getAttribute('maxLength');
    let numbers = input.value + value;
    numbers = numbers.replace(/[^0-9+]/g, '');

    if (numbers.length <= +amount) {
      input.setValue(numbers);
      if (nextInput && input.value.length === +amount) {
        nextInput.focus();
      }
    }
  }

  onClickNext(e) {
    e.preventDefault();
    if (this.formPin.valid) {
      this.nextStepService.nextThirdStep(this.formPin, this.isActiveCard).subscribe(
        result => {
          if (result) {
            this.router.navigate(['/thirdStep']);
            sessionStorage.setItem('prevStep', this.formPin.valid.toString());
          }
        },
        error => {
          if (error.error && error.error.code) {
            const parent = this.cardPin.nativeElement.closest('.entry-input_wrap');
            const elem = document.createElement('div');
            elem.classList.add('error-wrapper', 'error-back');
            parent.appendChild(elem);
            elem.style.cssText = 'display: block; text-align: left;';
            switch (error.error.code) {
              case -2:
              case 403:
              case 500: {
                elem.textContent = 'Ошибка ввода данных';
                break;
              }
              case -3: {
                elem.textContent = 'Превышено количество попыток ввода ПИН кода';
                break;
              }
              case -4: {
                elem.textContent = 'Пустой ПИН код';
                break;
              }
              case -5: {
                elem.textContent = 'ПИН код должен состоять только из цифр';
                break;
              }
              case -6: {
                elem.textContent = 'ПИН код должен состоять из 4 цифр';
                break;
              }
              case -7: {
                elem.textContent = 'ПИН код не может быть палиндромом';
                break;
              }
              case -8: {
                elem.textContent = 'ПИН код не может состоять из одинаковых цифр';
                break;
              }
              case -9: {
                elem.textContent = 'ПИН код не может состоять из последовательных цифр';
                break;
              }
              case -10: {
                elem.textContent = 'Сессия истекла, начните процесс смены pin-кода сначала';
                break;
              }
              case 99: {
                elem.textContent = 'Сервис временно недоступен, просьба обратиться позднее';
                break;
              }
            }
          }
        }
      );
    }
  }

  onFocus(e) {
    this.inputName = e.target.getAttribute('formControlName');
    e.target.setAttribute('type', 'text');
    if (this.inputName) {
      const eyes = e.target.closest('.entry-card').querySelectorAll('.img-look');
      eyes.forEach(elem => {
        if (elem.previousElementSibling.getAttribute('formControlName') === this.inputName) {
          elem.classList.remove(this.CLOSE_EYE);
        } else {
          elem.classList.add(this.CLOSE_EYE);
        }
      });
    } else {
      Array.from(document.querySelectorAll('.img-look')).forEach(item => item.classList.add(this.CLOSE_EYE));
      this.showKbd = false;
    }
    Array.from(Object.keys(this.formPin.controls)).forEach(el => {
      if (el !== 'active' && el !== 'agree' && el !== this.inputName && this.formPin.value[el]) {
        document.querySelector(`[formControlName="${el}"]`).setAttribute('type', 'password');
      }
    });
  }

  onClickChangeLook(e) {
    if (e.target.classList.contains(this.CLOSE_EYE)) {
      this.r2.removeClass(e.target, this.CLOSE_EYE);
      e.target.previousElementSibling.setAttribute('type', 'text');
    } else {
      this.r2.addClass(e.target, this.CLOSE_EYE);
      e.target.previousElementSibling.setAttribute('type', 'password');
    }
  }

  onDelete() {
    const Element = this.formPin.controls[this.inputName];
    Element.setValue(Element.value.substring(0, Element.value.length - 1));
  }

  onClickKey(value: string) {
    this.changeInput(this.nextEl, value);
  }

  onChange(nextEl, currentEl) {
    if (nextEl) {
      this.nextEl = nextEl;
    }
    currentEl.type = 'text';
  }

  onClickedOutside(e) {
    if (!e.target.classList.contains('virtual-kbd_img')) {
      this.showKbd = !this.showKbd;
    }
  }

  cleanInputs() {
    this.formPin.get('cardPin').setValue('');
    this.formPin.get('cardPinRepeat').setValue('');
  }
}
