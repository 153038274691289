import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pin-chat-tooltip',
  templateUrl: './chat-tooltip.component.html',
  styleUrls: ['./chat-tooltip.component.less']
})
export class ChatTooltipComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // Webim
    const webIM = document.createElement('a');
    webIM.classList.add('webim_button');
    webIM.href = '#';
    webIM.rel = 'webim';
    const img = document.createElement('img');
    img.src = 'https://chat-webim.rsb.ru/button.php?location=anketa';
    img.style.border = 'none';
    webIM.appendChild(img);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
  webim = {
    accountName: "rsbru",
    domain: "chat-webim.rsb.ru",
  };
  (function () {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://chat-webim.rsb.ru/js/button.js";
      document.getElementsByTagName("head")[0].appendChild(s);
  })();`;
    document.getElementsByTagName('head')[0].appendChild(script);
    document.getElementsByClassName('tooltip-box')[0].appendChild(webIM);
  }
}
