import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { timer } from 'rxjs';
import { routeChangeAnimation } from './app.animations';
import 'element-closest-polyfill';
import { NextStepService } from './services/next-step.service';

@Component({
  selector: 'pin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [routeChangeAnimation]
})
export class AppComponent implements OnInit {
  title = 'PIN.RSB.RU - формирование pin-кода и активация карты';

  isFrame = false;

  constructor(private route: ActivatedRoute, private nextStepService: NextStepService) {}

  ngOnInit(): void {
    const subscription = this.route.queryParams.subscribe(params => {
      this.isFrame = !!params['iframe'] && params.iframe === 'true';
      this.nextStepService.isFrame = this.isFrame;
    });
    timer(100).subscribe(() => subscription.unsubscribe());
  }

  getRouteAnimationState(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
