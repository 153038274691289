import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'pin-v-keyboard',
  templateUrl: './v-keyboard.component.html',
  styleUrls: ['./v-keyboard.component.less']
})
export class VKeyboardComponent implements OnInit, AfterViewInit {
  showKbd = true;

  @Output() clickClose = new EventEmitter<boolean>();

  @Output() clickKey = new EventEmitter<string>();

  @Output() delete = new EventEmitter<boolean>();

  @ViewChild('kbd', { static: false }) keyboard: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.changePosition();
  }

  closeKeyboard() {
    this.showKbd = !this.showKbd;
    this.clickClose.emit(this.showKbd);
  }

  onClickKey(e) {
    if (e.target.classList.contains('virtual-keyboard__number')) {
      this.clickKey.emit(e.target.textContent);
    }
  }

  onClickDelete() {
    this.delete.emit();
  }

  changePosition() {
    const virtualKeyboard = this.keyboard.nativeElement;
    const keyboardWidth = virtualKeyboard.clientWidth;
    const keyboardHeight = virtualKeyboard.clientHeight;
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;
    const topStart = Math.floor(virtualKeyboard.getBoundingClientRect().top);
    const leftStart = -Math.floor(virtualKeyboard.getBoundingClientRect().left);
    const left = Math.floor(Math.random() * (pageWidth - keyboardWidth + leftStart) + leftStart);
    const top = Math.floor(Math.random() * (pageHeight - keyboardHeight - topStart));
    virtualKeyboard.style.left = `${left}px`;
    virtualKeyboard.style.top = `${top}px`;
  }
}
